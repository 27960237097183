.messages-window {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
}

.messages-window .ant-comment-content-detail {
  white-space: pre-line;
}

.messages-window .ant-comment-inner {
  border-radius: 10px;
  margin-left: -12px;
  padding: 13px 12px 10px;
}

.comment:first-child {
  margin-bottom: 15px;
}

.reply-comment {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.messages-window .ant-comment-inner .ant-comment-avatar {
  width: 32px;
  height: 32px;
  overflow: hidden;
}

.ant-comment {
  padding: 0 12px 0 !important;
  border-radius: 15px;
  padding-right: 0 !important;
}

.to-reply .ant-comment-inner {
  /* background: none !important; */
}

.unread > .ant-comment > .ant-comment-inner {
  border-radius: 15px;
  background: #f3f5fc;
}

.messages-window .ant-comment-nested .ant-comment-inner {
  margin-left: -10px;
  padding: 12px 12px 10px;
}

.message-file-image {
  width: 70px;
  height: 70px;
  margin-top: 8px;
  margin-right: 8px;
  border: 1px solid rgba(29,28,29, .1);
  box-shadow: 0px 2px 2px rgb(77 84 110 / 12%), 0px 0px 1px rgb(77 84 110 / 40%);
  border-radius: 8px;
  cursor: zoom-in;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-file-image > img {
  border-radius: 8px;
  width: 70px;
  height: 70px;    
  object-fit:cover;
}

.message-file {
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(77, 84, 110, 0.12), 0px 0px 1px rgba(77, 84, 110, 0.4);
  border-radius: 6px;
  width: 233px;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 15px;
  margin-top: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  position: relative;
}


.message-file-data > .file-name {
  font-size: 14px;
  max-width: 120px;
}

.message-file-data > .file-size {
  font-size: 12px;
  color: #7F85A2;
}

.download-file {
  width: 28px;
  height: 28px;
  background: rgba(143, 146, 161, 0.2);
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in-out;
  cursor: pointer;
}

.download-file:hover {
  background: rgba(143, 146, 161, 0.4);
  border-radius: 7px;
}

.message-action {
  cursor: pointer;
  margin: 10px 8px 2px 0;
  max-width: 220px;
  min-width: 0;
  padding: 2px 10px;
  vertical-align: middle;
  background: rgba(255,255,255,1);
  background-clip: padding-box;
  border: 1px solid rgba(29,28,29,0.3);
  font-weight: 600;
  transition: all 80ms linear;
  border-radius: 5px;
}

.message-action:hover {
  background: rgb(239, 239, 239);
}

.only-for-you > .ant-comment > .ant-comment-inner::before {
  color: rgba(29,28,29,.7);
  font-size: 12px;
  content: "Only visible for you";
}

.only-for-you > .ant-comment > .ant-comment-inner {
  display: block;
  background: rgba(29,28,29,.04) !important;
}

.only-for-you > .ant-comment > .ant-comment-inner > .ant-comment-content {
  margin-top: 4px;
}