.reply-to-placeholder {
  font-size: 12px;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: #f5f5f5;
  padding: 5px 5px 5px 13px;
  margin-bottom: 5px;
  border-radius: 6px;
  white-space: nowrap;
}

.reply-to-placeholder .ant-col {
  overflow: hidden;
  text-overflow: ellipsis;
}

.reply-field {
  position: static !important;
}

.reply-field .ant-comment-inner {
  padding: 0 !important;
}
