.image-modal-container {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 23px;
  z-index: 1001;
  background: #0009;
}

.image-modal {
  background: #000000b3;
  box-shadow: 0 0 0 1px rgba(29,28,29,.1);
  color: rgba(29,28,29,1);
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
}

.p-media_viewer_modal__blurry_background {
  background-color: #000;
  background-position: 50%;
  background-size: cover;
  bottom: -100px;
  filter: blur(40px) brightness(.4);
  left: -100px;
  pointer-events: none;
  position: absolute;
  right: -100px;
  top: -100px;
}

.image-modal-header {
  padding: 15px 20px;
  transition: all .1s ease-in-out;
  transform: translateY(-20px);
  opacity: 0;
}

.image-modal-footer {
  padding: 15px 20px;
  transition: all .1s ease-in-out;
  transform: translateY(20px);
  opacity: 0;
}

.im-user-logo {
  margin-right: 10px;
}

.im-user-logo > img {
  border-radius: 5px;
  width: 45px;
  height: 45px;
}

.image-modal:hover > .image-modal-footer {
  opacity: 1;
  transform: translateY(0px);
}
.image-modal:hover > .image-modal-header {
  opacity: 1;
  transform: translateY(0px);
}



.im-container {
  display: flex;
  justify-content: center;
  align-items: center;
}