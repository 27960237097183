.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab {
  padding-left: 0;
  padding-right: .2rem;
}

.ant-tabs-content-holder {
  border-left: none !important;
}

.custom-divider {
  width: 1px;
  position: relative;
}

.custom-divider::before {
  transition: .3s all ease-in-out;
  z-index: 100;
  height: 100%;
  position: absolute;
  content: "";
  width: 8px;
  border-left: 1px solid rgba(0, 0, 0, .06);
  cursor: col-resize;
}

.custom-divider:active::before {
  border-left: 3px solid #1164A3;
}

.custom-divider:hover::before {
  border-left: 2px solid #1164A3;
}

@media (max-width: 640px) {
  .channel-list-hidden>.ant-tabs-nav {
    display: none !important;
  }

  .channel-list-block>.ant-tabs-nav {
    width: 100% !important;
  }

  .custom-divider {
    display: none !important;
  }

  .channel-list-block>.ant-tabs-content-holder {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .channel-list-hidden>.ant-tabs-nav {
    display: none !important;
  }

  .channel-list-block>.ant-tabs-nav {
    display: block !important;
  }
}

#sidebar-inner {
  display: flex;
}

.ant-tabs-tab-btn {
  font-size: 14px;
  padding-left: 0;
  width: 100%;
  text-align: left;
}

p {
  font-size: 14px;
}

.chat-header {
  padding: 0 24px;
  border-bottom: 1px solid rgba(0, 0, 0, .06);
}

.chat-header .ant-page-header-heading {
  flex-wrap: nowrap;
  align-items: center !important;
}

.chat-header .ant-page-header-heading-title {
  line-height: 23px;
}

.chat-header .ant-page-header-heading-title .ant-typography {
  position: relative;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  padding-right: 32px;
}

.chat-header .ant-page-header-heading-title .ant-typography-edit {
  position: absolute;
  bottom: -1px;
  right: 0;
}

.chat-header .ant-typography p,
.chat-header div.ant-typography {
  font-size: 18px !important;
  margin-bottom: 0;
}

.chat-header .ant-page-header-heading-extra {
  height: 37px;
  margin: 8px 0;
}

.chat-header>.ant-space-align-center {
  display: flex !important;
  align-items: center !important;
}

.chat-tabs .ant-tabs-content {
  height: 100%;
  position: relative;
}

.chat-tab-channel {
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-badges {
  transition: width 0.2s ease-in-out;
}

.tab-badges.active {
  width: 26px;
}

.tab-badges .ant-badge-dot {
  transform: none;
}

.tab-badges .ant-badge-count {
  margin: -8px 0 0 -5px;
}

[contentEditable=true]:empty:before {
  content: attr(data-text);
  color: #7F85A2;
}

.message-container {
  box-shadow: 0px 2px 2px rgba(77, 84, 110, 0.12), 0px 0px 1px rgba(77, 84, 110, 0.4);
  border-radius: 12px;
}

#message-input-area {
  font-family: "Proxima Nova";
  outline: none;
  padding: 15px;
  padding-right: 5rem;
}

.ant-comment-content-author-time {
  font-size: 14px !important;
  color: #7F85A2;
}

.ant-comment-content-author-name {
  font-family: 'Proxima Nova';
  font-size: 14px !important;
  color: #1E1F20;
  font-weight: 700;
}

.ant-comment-avatar {
  margin-right: 16px !important;
}

.ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 12px !important;
}

.ant-comment-content {
  position: static !important;
  margin-top: 8px;
}

.reply-field .ant-comment-content {
  margin-top: 0;
}

.reply-field .ant-form-item {
  margin-bottom: 0px !important;
}

.remove-file {
  position: absolute;
  right: -11px;
  top: -11px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #5D667B;
  display: none;
  justify-content: center;
  align-items: center;
  color: white;
}

.message-file:hover>.remove-file {
  display: flex;
  cursor: pointer;
}

.message-file-image:hover>.remove-file {
  display: flex;
  cursor: pointer;
}

.attached-files {
  overflow-x: auto;
}

.file-image {
  box-shadow: 0 0 1px rgba(29, 28, 29, 0.13);
  height: 62px;
  aspect-ratio: auto 62 / 62;
  width: 62px;
  border-radius: 12px;
  object-fit: cover;
}

.file-image-container {
  position: relative;
  width: 64px;
  height: 64px;
  margin-right: 10px;
  border-radius: 12px;
  border: 1px solid rgba(29, 28, 29, .1);
  user-select: none;
}

.file {
  position: relative;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(29, 28, 29, .1);
  border-radius: 12px;
  display: flex;
  padding: 7px 12px;
  transition-duration: .1s;
  transition-property: border-color, box-shadow;
  width: 208px;
  margin-right: 10px;
  user-select: none;
}

.file-logo {
  margin-right: 10px;
  background: #3aa3e3;
  width: 35px;
  height: 35px;
  border-radius: 8px;
  padding: 3px;
  align-self: center;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-name {
  font-size: 15px;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 140px;
}

.ant-comment-content-detail {
  font-family: 'Proxima Nova';
  font-size: 14px;
  color: #1D1D1D;
}

.ant-comment-actions {
  display: flex;
  align-items: center;
  position: relative;
}

.add-reactions-logo {
  width: 14px;
  height: 14px;
  -webkit-mask: url(../../static/images/reaction.svg) no-repeat center;
  mask: url(../../static/images/reaction.svg) no-repeat center;
  background-color: #5D667B;
}

.add-reactions:hover>.add-reactions-logo {
  background-color: white;
}

.two-user-icon {
  width: 18px;
  height: 11px;
  -webkit-mask: url(../../static/images/2_user.svg) no-repeat center;
  mask: url(../../static/images/2_user.svg) no-repeat center;
  background-color: #5D667B;
}

.add-user-icon {
  width: 18px;
  height: 13px;
  -webkit-mask: url(../../static/images/add_user.svg) no-repeat center;
  mask: url(../../static/images/add_user.svg) no-repeat center;
  background-color: #5D667B;
}

.ant-spin-spinning {
  padding-top: 5px;
}

.two-user:hover>div {
  color: white !important;
}

.add-user:hover>.add-user-icon {
  background-color: white;
}

.two-user:hover>.two-user-icon {
  background-color: white;
}

.selected-project-type {
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(77, 84, 110, 0.12), 0px 0px 1px rgba(77, 84, 110, 0.4);
  color: #4F67FF;
}